import {
  useApiAsyncFetch,
  useAuthApiAsyncFetch,
} from '@/composables/proxyApiUseFetch'

// PUBLIC

export async function usePartnerUserExistsAsyncFetch(email: string) {
  return await useApiAsyncFetch(`/partner-users/exists`, {
    params: {
      email,
    },
  })
}

export async function usePartnerUserDomainExistsAsyncFetch(domain: string) {
  return await useApiAsyncFetch(`/partner-users/domain-exists`, {
    params: {
      domain,
    },
  })
}

export async function usePartnerUserCreateAsyncFetch(body: object) {
  return await useApiAsyncFetch(`/partner-users/create`, {
    method: 'POST',
    body,
  })
}

export async function useGetPartnerUserFieldsAsyncFetch() {
  return await useApiAsyncFetch('/partner-users/fields')
}

export async function usePartnerUserSendEmailVerificationAsyncFetch(
  email: string
) {
  return await useApiAsyncFetch(`/partner-users/send-email-verification`, {
    method: 'POST',
    body: {
      email,
    },
  })
}

// AUTH

export async function useAuthPartnerUserUpdateAsyncFetch(body: object) {
  return await useAuthApiAsyncFetch(`/auth/partner-users/update`, {
    method: 'POST',
    body,
  })
}

export function useAuthPartnerUserUpdateFetch(body: object) {
  return useAuthApiFetch(`/auth/partner-users/update`, {
    method: 'POST',
    body,
  })
}

export async function useAuthPartnerUserProfileUpdateAsyncFetch(body: object) {
  return await useAuthApiAsyncFetch(`/auth/partner-users/profile/update`, {
    method: 'POST',
    body,
  })
}

export async function useAuthPartnerUserChangePasswordAsyncFetch(body: object) {
  return await useAuthApiAsyncFetch(
    `/auth/partner-users/profile/change-password`,
    {
      method: 'POST',
      body,
    }
  )
}

export async function useAuthSendPartnerUserSMSInvitationAsyncFetch(
  phone: string,
  partnerName: string,
  message: string
) {
  return await useAuthApiAsyncFetch(`/auth/partner-users/sms-invitation`, {
    method: 'POST',
    body: {
      phone,
      partnerName,
      message,
    },
  })
}

export async function useAuthSendPartnerUserEmailInvitationAsyncFetch(
  email: string,
  byUser: object
) {
  const parentPartner = await useGetParentPartnerAsyncFetch(byUser)

  return await useAuthApiAsyncFetch(`/auth/partner-users/email-invitation`, {
    method: 'POST',
    body: {
      email,
      partnerName: parentPartner.name,
      username: `${byUser.firstname} ${byUser.lastname}`,
    },
  })
}

export async function useAuthPartnerUserLogsAsyncFetch() {
  return await useAuthApiAsyncFetch(`/auth/partner-users/auth-logs`)
}

export async function useGetParentPartnerAsyncFetch(partnerUser: object) {
  // Si c'est un utilisateur partenaire parent
  if (!partnerUser._user_child) {
    return partnerUser.partner
  }

  if (partnerUser.partner.parent) {
    const { data: fetchParentPartner, error: fetchParentPartnerError } =
      await useAuthPartnerAsyncFetch(partnerUser.partner.parent)

    if (!fetchParentPartnerError.value) {
      return fetchParentPartner.value
    }
  }

  return {}
}

export async function useAuthSelectedUserChildPartnersAsyncFetch() {
  return await useAuthApiAsyncFetch(
    `/auth/partner-users/selected-user-child-partners`
  )
}
