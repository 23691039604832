const _inherit = "inherit"
const _current = "currentColor"
const _transparent = "transparent"
const _black = "#000000"
const _white = "#FFFFFF"
const _slate = null
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)"}
const _zinc = null
const _neutral = null
const _stone = null
const _red = {"50":"#fef2f2","100":"#fee2e2","200":"#fecaca","300":"#fca5a5","400":"#f87171","500":"#ef4444","600":"#dc2626","700":"#b91c1c","800":"#991b1b","900":"#7f1d1d","950":"#450a0a"}
const _orange = null
const _amber = null
const _yellow = null
const _lime = null
const _green = null
const _emerald = null
const _teal = null
const _cyan = null
const _sky = null
const _blue = {"50":"#eff6ff","100":"#dbeafe","200":"#D9E0E5","300":"#93c5fd","400":"#60a5fa","500":"#002F54","600":"#2563eb","700":"#1d4ed8","800":"#1e40af","900":"#1e3a8a","950":"#172554","DEFAULT":"#002F54"}
const _indigo = null
const _violet = null
const _purple = null
const _fuchsia = null
const _pink = null
const _rose = null
const _lightgreen = "#08FFA0"
const _container = "#F9F9F9"
const _grey = {"100":"#E3E3E3","200":"#C3C3C3","500":"#667080","DEFAULT":"#667080","disable":"#D3D3D3"}
const _secondary = {"500":"#269999","DEFAULT":"#269999","light":"#00BEB2"}
const _tertiary = {"red":"#99263B"}
const _error = {"500":"#FF613F","DEFAULT":"#FF613F"}
const _warning = {"500":"#F0DF47","DEFAULT":"#F0DF47"}
const _success = {"500":"#57E026","DEFAULT":"#57E026"}
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _cool = {"50":"#f9fafb","100":"#f3f4f6","200":"#e5e7eb","300":"#d1d5db","400":"#9ca3af","500":"#6b7280","600":"#4b5563","700":"#374151","800":"#1f2937","900":"#111827","950":"#030712"}
const config = { "inherit": _inherit, "current": _current, "transparent": _transparent, "black": _black, "white": _white, "slate": _slate, "gray": _gray, "zinc": _zinc, "neutral": _neutral, "stone": _stone, "red": _red, "orange": _orange, "amber": _amber, "yellow": _yellow, "lime": _lime, "green": _green, "emerald": _emerald, "teal": _teal, "cyan": _cyan, "sky": _sky, "blue": _blue, "indigo": _indigo, "violet": _violet, "purple": _purple, "fuchsia": _fuchsia, "pink": _pink, "rose": _rose, "lightgreen": _lightgreen, "container": _container, "grey": _grey, "secondary": _secondary, "tertiary": _tertiary, "error": _error, "warning": _warning, "success": _success, "primary": _primary, "cool": _cool,  }
export { config as default, _inherit, _current, _transparent, _black, _white, _slate, _gray, _zinc, _neutral, _stone, _red, _orange, _amber, _yellow, _lime, _green, _emerald, _teal, _cyan, _sky, _blue, _indigo, _violet, _purple, _fuchsia, _pink, _rose, _lightgreen, _container, _grey, _secondary, _tertiary, _error, _warning, _success, _primary, _cool }