import _ from 'lodash'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const config = useRuntimeConfig()

  const maintenanceActive = config.public.maintenance.active
  const maintenanceSecret = config.public.maintenance.secret
  const cookieToken = useCookie('bypass_maintenance', {
    maxAge: 2 * 60 * 60, // 2h
  })
  const toQuerySecret = _.get(to.query, 'secret')

  if (toQuerySecret === maintenanceSecret) {
    cookieToken.value = toQuerySecret
  }

  if (maintenanceActive) {
    if (cookieToken.value !== maintenanceSecret && to.name !== 'maintenance') {
      return navigateTo({ name: 'maintenance' })
    }
  } else if (to.name === 'maintenance') {
    return navigateTo('/')
  }
})
