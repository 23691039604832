export default defineAppConfig({
  ui: {
    primary: 'blue',
    gray: 'grey',
    container: {
      base: 'w-full mx-auto px-container-mobile sm:px-container-mobile lg:px-container',
      padding: '',
      constrained: 'lg:max-w-[1150px] xl:max-w-[1200px]',
    },
    button: {
      base: 'button text-center lg:min-w-[120px] justify-center',
      font: 'font-opensans font-bold',
      rounded: 'rounded-4',
      padding: {
        md: 'px-10 !py-12',
        lg: 'px-10 !py-12',
      },
      size: {
        md: '!text-14 !leading-none',
        lg: '!text-14 !leading-none',
      },
      gap: {
        md: 'gap-x-10',
        lg: 'gap-x-10',
      },
      icon: {
        size: {
          md: 'w-12 h-12',
          lg: 'w-12 h-12',
        },
      },
      color: {
        primary: {
          text: 'text-primary underline border-0',
          accordion:
            'border border-grey rounded-10 font-bold tracking-[-0.28px] py-12 lg:py-14 pl-21 lg:pl-44 pr-48 lg:pr-44 !mb-16 !text-left',
          solid:
            'disabled:text-white disabled:bg-grey-disable disabled:border-grey-disable disabled:opacity-100 disabled:hover:bg-grey-disable disabled:hover:text-white border-2 border-primary bg-primary text-white hover:bg-white hover:text-primary transition-colors duration-500',
          outline:
            'disabled:text-white disabled:bg-grey-disable disabled:border-grey-disable disabled:opacity-100 disabled:hover:bg-grey-disable disabled:hover:text-white border-2 border-primary bg-transparent text-primary hover:bg-primary hover:text-white transition-colors duration-500',
          link: 'text-primary-500 hover:text-primary-400 p-0',
        },
        grey: {
          outline:
            'disabled:text-white disabled:bg-grey-disable disabled:border-grey-disable disabled:opacity-100 disabled:hover:bg-grey-disable disabled:hover:text-white border-2 border-grey-100 bg-transparent text-grey hover:bg-grey-100 hover:text-white transition-colors duration-500',
        },
        secondary: {
          solid:
            'disabled:text-white disabled:bg-grey-disable disabled:border-grey-disable disabled:opacity-100 disabled:hover:bg-grey-disable disabled:hover:text-white  border border-secondary-500 bg-secondary-500 text-white hover:bg-secondary-400',
          outline:
            'disabled:text-white disabled:bg-grey-disable disabled:border-grey-disable disabled:opacity-100 disabled:hover:bg-grey-disable disabled:hover:text-white  border border-secondary-500 bg-transparent text-secondary-500 hover:bg-secondary-500 hover:text-white',
        },
        secondaryLight: {
          solid:
            'disabled:text-white disabled:bg-grey-disable disabled:border-grey-disable disabled:opacity-100 disabled:hover:bg-grey-disable disabled:hover:text-white  border border-secondary-light bg-secondary-light text-white hover:bg-secondary-400',
          outline:
            'disabled:text-white disabled:bg-grey-disable disabled:border-grey-disable disabled:opacity-100 disabled:hover:bg-grey-disable disabled:hover:text-white  border border-secondary-light bg-transparent text-secondary-light hover:bg-secondary-500 hover:text-white',
        },
        primaryLight: {
          solid:
            'border border-primary-400 bg-primary-400 text-white hover:bg-primary-500',
        },
        white: {
          text: 'text-white underline border-0',
          outline:
            'disabled:text-white disabled:bg-grey-disable disabled:border-grey-disable disabled:opacity-100 disabled:hover:bg-grey-disable disabled:hover:text-white border-2 border-primary bg-transparent text-primary hover:bg-primary hover:text-white transition-colors duration-500',
          solid:
            'disabled:text-white disabled:bg-grey-disable disabled:border-grey-disable disabled:opacity-100 disabled:hover:bg-grey-disable disabled:hover:text-white  border border-white bg-white text-primary hover:bg-primary hover:text-white',
        },
        transparent: {
          outline:
            'disabled:text-white disabled:bg-grey-disable disabled:border-grey-disable disabled:opacity-100 disabled:hover:bg-grey-disable disabled:hover:text-white border-2 border-white bg-transparent text-white hover:bg-white hover:text-primary transition-colors duration-500',
        },
      },
      default: {
        size: 'md',
      },
    },
    modal: {
      overlay: {
        base: 'fixed inset-0 transition-opacity',
        background: 'bg-black opacity-50',
      },
      base: 'p-24 lg:p-48 rounded-10',
      width: 'sm:max-w-[90vw] lg:max-w-[1300px]',
      margin: 'sm:my-30',
    },
    carousel: {
      item: 'w-full',
      indicators: {
        base: 'carousel-indicator',
        inactive: 'bg-grey-100',
        active: 'bg-primary',
        wrapper: 'gap-16',
      },
    },
    tabs: {
      list: {
        base: 'tabs w-full gap-0 lg:gap-x-30 !flex overflow-auto no-scrollbar',
        height: '',
        padding: '',
        marker: {
          wrapper: 'hidden',
        },
        background: 'bg-white',
        tab: {
          base: 'w-auto tab focus:!shadow-none focus:!ring-0',
          inactive: 'text-black',
          active: 'text-secondary border-b-2 border-secondary',
          size: 'text-basic',
          font: 'font-semibold',
          height: '',
          padding: 'px-10 pb-20',
        },
      },
    },
    accordion: {
      wrapper: 'accordion',
      item: {
        color: 'text-black',
        size: 'text-button',
        padding: 'lg:pb-16',
        icon: '!w-12 !h-12 translate-x-18 lg:translate-x-28',
      },
      default: {
        variant: 'accordion',
        openIcon: 'i-fa6-solid-chevron-down',
      },
    },
    select: {
      base: 'flex items-center disabled:bg-grey-100',
      rounded: 'rounded-4',
      placeholder: 'text-grey-200',
      padding: {
        md: 'pl-16 py-14 pr-40',
      },
      size: {
        md: 'text-p2 leading-20 cursor-pointer',
      },
      color: {
        primary: {
          solid: 'bg-transparent text-primary ring-0 focus:ring-0',
        },
        gray: {
          outline: 'bg-grey-200 text-primary-500 focus:ring-0',
        },
        white: {
          solid:
            'bg-white border border-grey-200 focus:border-secondary hover:border-black focus:ring-0',
        },
        red: {
          solid:
            'bg-white border border-error focus:border-secondary hover:border-black focus:ring-0',
        },
      },
      icon: {
        size: {
          md: 'w-24 h-24',
        },
        trailing: {
          wrapper: 'right-16 text-grey-200',
        },
      },
      default: {
        color: 'white',
        size: 'md',
        variant: 'solid',
        icon: 'i-fa6-solid-chevron-down',
      },
    },
    selectMenu: {
      base: 'flex flex-col items-start justify-start',
      ring: 'ring-0',
      trigger: 'inline-flex items-center',
      shadow: 'shadow-none',
      height: 'h-auto max-h-[220px]',
      width: 'w-full min-w-[220px]',
      padding: 'p-0',
      background: 'bg-white shadow-[0_0_4px_rgba(0,0,0,0.25)]',
      option: {
        selectedIcon: {
          base: 'hidden',
        },
        padding: 'p-8',
        base: 'text-button !bg-transparent',
        selected: '',
      },
    },
    checkbox: {
      wrapper: 'relative flex items-start custom-checkbox',
      base: 'checkbox h-24 w-24 checked:bg-secondary checked:shadow-[0_0_4px_1px_rgba(38,153,153,0.25)] max-lg:bg-transparent',
      border: 'border border-grey-200',
      ring: 'ring-0',
      rounded: 'rounded-2',
      label: 'text-p3 ml-10 mt-2 flex-1',
      default: {
        color: 'secondary',
      },
    },
    tooltip: {
      base: 'h-auto text-12 p-8',
      rounded: 'rounded-4',
      width: 'max-w-none',
    },
    formGroup: {
      label: {
        base: 'text-black mb-8 font-normal',
        required: "after:content-['*'] after:ms-0.5 after:text-black",
      },
      error: 'mt-8 !text-[12px] !leading-20 !text-error',
      size: {
        md: 'text-14 leading-20',
      },
      default: {
        size: 'md',
      },
    },
    textarea: {
      rounded: 'rounded-4',
      size: {
        md: 'text-p2 leading-20',
      },
      padding: {
        md: 'px-16 py-14',
      },

      color: {
        white: {
          outline:
            'ring-0 focus:ring-0 shadow-none focus:border-secondary hover:border-black border placeholder:text-gray-100 text-black border-grey-200',
        },
        success: {
          outline:
            'ring-0 focus:ring-0 shadow-none focus:border-secondary hover:border-black border placeholder:text-gray-100 text-black border-success',
        },
        red: {
          outline:
            'ring-0 focus:ring-0 shadow-none focus:border-error hover:border-error border placeholder:text-gray-100 text-black border-error',
        },
      },
    },
    input: {
      rounded: 'rounded-4',
      size: {
        md: 'text-p2 leading-20',
      },
      padding: {
        md: 'px-16 py-14',
      },
      base: 'disabled:bg-grey-100',
      color: {
        white: {
          outline:
            'ring-0 focus:ring-0 shadow-none focus:border-secondary hover:border-black border placeholder:text-gray-200 text-black border-grey-200',
        },
        success: {
          outline:
            'ring-0 focus:ring-0 shadow-none focus:border-secondary hover:border-black border placeholder:text-gray-200 text-black border-success',
        },
        red: {
          outline:
            'ring-0 focus:ring-0 shadow-none focus:border-error hover:border-error border placeholder:text-gray-200 text-black border-error',
        },
      },
      icon: {
        trailing: {
          padding: {
            md: 'px-16',
          },
        },
      },
      default: {
        size: 'md',
      },
    },
    notifications: {
      container: 'toast',
      position: 'bottom-20 right-30',
      width: 'sm:w-[300px]',
    },
    notification: {
      padding: 'p-10',
      progress: {
        base: 'h-4',
      },
    },
    dropdown: {
      wrapper: 'dropdown',
    },
  },
})
